import { CUSTOMER_SERVICE_PHONE_NUMBER } from '@seek/shared-constants';
import { useTranslations } from '@vocab/react';
import { Heading, Stack, Text, List } from 'braid-design-system';
import React from 'react';

import { StaticPageProvider, TextLink } from 'src/components';
import { TRACKING_CODES } from 'src/shared/constant';
import { useSite } from 'src/shared/hooks';
import type { PhoneNumber, Site } from 'src/shared/types';

import { ErrorWrapper } from '../../components/ErrorWrapper/ErrorWrapper';

import translations from './.vocab';

const StaticPageProps = {
  title: '404 Page Not Found',
  returnUrl: '/',
  eventName: TRACKING_CODES.error_page_displayed,
  currentPage: 'error page',
  errorMessage: 'page not found',
};

const NotFound = () => {
  const { t } = useTranslations(translations);
  const site: Site = useSite();
  const isAnz = site.includes('au') || site.includes('nz');

  return (
    <StaticPageProvider {...StaticPageProps}>
      <ErrorWrapper>
        <Stack space="large" align="center">
          <Heading level="3">{t('we couldnt find that page')}</Heading>
          <Stack space="gutter" align="left">
            <Text>{t('maybe we can help')}</Text>
            <List type="number" space="medium">
              <Text>{t('check the url')}</Text>
              <Text>{t('try going back')}</Text>
              <Text>
                {isAnz
                  ? t('call customer service', {
                      TextLink: () => (
                        <TextLink
                          href={`tel: ${
                            (CUSTOMER_SERVICE_PHONE_NUMBER as PhoneNumber)[site]
                          }`}
                          tracking={
                            TRACKING_CODES.customer_service_phone_number_clicked
                          }
                          skipResolvedUrl
                        >
                          {(CUSTOMER_SERVICE_PHONE_NUMBER as PhoneNumber)[site]}
                        </TextLink>
                      ),
                    })
                  : t('call customer service asia', {
                      TextLink: (children) => (
                        <TextLink
                          href="/contactus?enquiry=page-not-found"
                          tracking={TRACKING_CODES.contact_us_clicked}
                        >
                          {children}
                        </TextLink>
                      ),
                    })}
              </Text>
            </List>
          </Stack>
        </Stack>
      </ErrorWrapper>
    </StaticPageProvider>
  );
};

export default NotFound;
